import { Link, useLocation } from 'react-router-dom';
import { ArrowLeftRight, BarChart2, Building2, CircleDollarSign, Settings } from 'lucide-react';
import UserAvatar from '@/pages/user/UserAvatar';
import { useCompany } from '@/data/company';
import { cn } from '@/helpers/shadcn';
import { useUserRoles } from '@/helpers/auth0';

const AppHeader = () => {
  const location = useLocation();
  const company = useCompany();
  const { isZebeler } = useUserRoles();

  const links = [
    { to: '/projects', icon: Building2, label: 'Project Database', hidden: false },
    { to: '/market', icon: BarChart2, label: 'Market', hidden: !company.data.is_market_data_visible },
    { to: '/compare', icon: ArrowLeftRight, label: 'Compare', hidden: false },
    { to: '/estimate', icon: CircleDollarSign, label: 'Estimate', hidden: !company.data.is_v1_estimate },
    {
      to: '/v2-estimate',
      icon: CircleDollarSign,
      label: company.data.is_v1_estimate ? 'V2 Estimate' : 'Estimate',
      hidden: !company.data.is_v2_estimate && !isZebeler
    },
    { to: '/settings', icon: Settings, label: 'Settings', hidden: false }
  ];

  return (
    <header className="fixed top-0 left-0 right-0 border-b h-[var(--app-header-height)] flex items-center gap-2 px-2 z-[40] bg-card">
      <div className="w-[250px] min-[250px] flex items-center gap-2">
        <Link to="/market">
          <img className="w-8 min-w-8" src="/assets/logos/zebel-logo-top-small-blue-bg.png" alt="Zebel logo" />
        </Link>
        <div className="font-semibold text-sm truncate">{company.data.company_name}</div>
      </div>
      <nav className="flex-auto h-full flex items-stretch justify-center gap-1 text-sm font-medium">
        {links
          .filter((link) => !link.hidden)
          .map((link) => {
            const IconComponent = link.icon;
            return (
              <Link
                key={link.to}
                to={link.to}
                className={cn(
                  'border-b-2 border-transparent transition-all hover:text-foreground/80 flex items-center gap-2 px-2 pt-1 truncate',
                  location.pathname.startsWith(link.to) ? 'text-[var(--zebel-primary-blue)] border-[var(--zebel-primary-blue)]' : 'text-foreground/60 '
                )}
              >
                <IconComponent className="w-4 h-4" />
                {link.label}
              </Link>
            );
          })}
      </nav>
      <div className="w-[250px] min-[250px] flex items-center justify-end">
        <UserAvatar />
      </div>
    </header>
  );
};

export default AppHeader;
